@import "../../scss/variables";

.modal-button {
  border-radius: 0;
  display: inline-block;
  font-size: 1rem;
  position: fixed;
  right: -2px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: bottom right;
  width: 150px;
  bottom: 15rem;
  z-index: 999;
}



.menu-slide-from-left {
  width: 700px;
  height: 700px;
  margin-top: 55px;
  transform: translateX(800px);
  border: 1px solid #d7d7d7;
  background-color: white;
  position: fixed;
  right: 0;
  bottom: 2rem;
  z-index: 999;

  @media (max-width: 767px) {
    &{
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 20px;
      bottom: 0;
    }
  }
}
.transition-after-pageload {
  transition: 0.8s;
}

.show-slide-from-left {
  transform: translateX(0px);
  transition: 0.8s;
}
.inner-menu-slide-from-left {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.title-form {
  margin: 50px 0;
  text-align: center;

  .title {
    font-size: 30px;
    font-weight: bold;
  }


  @media (max-width: 400px) {
    &{
      margin: 20px 0;
    }
  }
}
.button {
  text-align: center;
  padding: 10px 20px;
  font-weight: bold;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.contact-button {
  background-color: $primary;
  border: 2px solid $primary;
  color: $gray-f5;
  width: 100%;
}

.menu-options {
  color: $text-color;
  padding-top: 10px;
  padding-bottom: 10px;
}

.error-message {
  font-weight: 600;
  font-size: 10px;
  color: $error-text;
}

.close-button {
  // display: none;
  position: absolute;
  font-size: 25px;
  cursor: pointer;
  display: inline;

  @media (max-width: 400px) {
    &{

    }
  }
}
