@import "../../scss/variables";

.highlight {
	font-size: 1.3rem;
	line-height: 1.6;
	color: $gray;
}

h3 {
	font-size: 1.4rem;
	font-weight: $bold;
	text-transform: uppercase;
}

h6 {
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: $bold;
}

.ico-box {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 40px;

	img {
		margin-bottom: 0.5rem;
	}
}

.ico-step {
	$base-dimension: 55px;
	$difference: 15px;
	$bigger: $base-dimension + $difference;
	$line-position: $base-dimension/2 + 2px;
	$color: darken($gray-e5, 15);

	.step-img>div {
		border-radius: 50%;
		//border: 2px solid $color;
		box-shadow: 0 0 10px 2px rgba(#000, 0.15);
		width: $base-dimension;
		height: $base-dimension;
		padding: 10px 0;
		text-align: center;
		margin-top: $difference;
		background-color: white;
		position: relative;
		margin-bottom: 1rem;

		span {
			color: white;
			background-color: $color;
			font-weight: bold;
			height: 20px;
			width: 20px;
			line-height: 20px;
			font-size: 0.8rem;
			display: inline-block;
			text-align: center;
			border-radius: 50%;
			position: absolute;
			bottom: 5px;
			left: -6px;
		}


	}

	&:after,
	&:before {
		background-color: $gray-e5;
		content: "";
		z-index: -1;
		height: 1px;
		position: absolute;
		top: $base-dimension;
	}

	&:after {
		width: calc(100% - #{$line-position});
		left: $line-position;
	}

	&:before {
		width: $line-position;
		left: 0px;
	}

	&:first-child:before {
		width: 0;
	}

	&:last-child {
		&:after {
			width: 0;
			left: $line-position;
		}

		div.step-img>div {
			margin-top: 0;
			width: $bigger;
			height: $bigger;
		}
	}

	@media screen and (max-width: 992px) {
		>div {
			vertical-align: middle;
			margin-bottom: 1rem;
		}

		.step-text {
			display: inline-block;
			width: 70%;
		}

		.step-img {
			display: inline-block;
			width: 12%;
			min-width: calc(#{$bigger} + 1rem);

			&>div {
				margin-top: 0;
			}
		}

		&:after,
		&:before {
			width: 1px !important;
			left: 18px !important;
		}

		&:after {
			height: calc(100% - #{$line-position});
			top: $line-position;
		}

		&:before {
			height: $line-position;
			top: 0px;
		}

		&:first-child:before {
			height: 0;
		}

		&:last-child:after {
			height: 0;
		}
	}
}

.grey-bg {
	background-color: $gray-f5;
}

.img-cover {
	object-fit: cover;
	width: 100%;
	max-height: 500px;
}

.img-cover-2 {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.service-item {
	padding: 10px;
}
.service-item:hover {
	background-color: #f5f5f5;
	font-weight: bold;
}

.img-service {
	height: 140px;
	width: 140px;
	margin: $spacer auto 2 * $spacer;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.img-round {
	height: 140px;
	width: 140px;
	margin: 4 * $spacer auto 2 * $spacer;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
}

.container {
	line-height: 1.5;
}

footer {
	border-top: 1px solid $gray-e5;
	color: $gray;
	text-align: center;
	padding: 5px 0;
	font-size: 0.8rem;

	a {
		color: $gray;
		text-decoration: underline;

		&:hover {
			color: $gray;
			text-decoration: none;
		}
	}
}