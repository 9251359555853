@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

/** Colors */
$primary: #0546E2;
$text-color: #333;
$gray-f5: #f5f5f5;
$gray-e5: #e5e5e5;
$gray: #999;
$error-text: #f26060;
$black: #000;
$white: #fff;

$theme-colors: ("primary": $primary);


/** Font */
$font-size: 16px;
$font-family: 'Noto Sans JP',
sans-serif;
$alternative: 'Noto Sans',
sans-serif;
$line-height: 1.3;
$bold: 700;

$headings-font-weight: 400;

/** Spacers */

$spacer: 7px;
$spacers: ();
$spacers: map-merge((0: 0,
		1: ($spacer * 1),
		2: ($spacer * 2),
		3: $spacer,
		4: ($spacer * 3),
		5: ($spacer * 4),
		6: ($spacer * 5),
	),
	$spacers);

/** Border-radius */
$border-radius: 4px;

$enable-shadows: true;
/** Buttons */
$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-font-family: $font-family;
$btn-line-height: 22px;
$btn-border-width: 1px;
$btn-box-shadow: none;
$btn-border-radius: $border-radius;
$btn-focus-width: 0;
$btn-font-weight: $bold;

/** Forms */

$label-margin-bottom: 2px;

$input-padding-y: 8px;
$input-padding-x: 8px;
$input-font-family: $font-family;
$input-line-height: 22px;
$input-height: 40px;

$input-color: $text-color;
$input-border-color: $gray-e5;
$input-border-width: 1px;
$input-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .05);
$input-group-addon-color:$gray;
$input-group-addon-bg: $gray-f5;
$input-group-addon-border-color: $gray-e5;

$input-border-radius: $border-radius;

$input-placeholder-color: $gray;

$input-focus-border-color: $primary;
$input-focus-color: $input-color;
$input-focus-box-shadow:0 0 10px 2px rgba(0, 0, 0, .10);