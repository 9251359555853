.tooltip-top {
  background-color: #292929;
  color: #c4c4c4;
  padding: 4px 4px 4px 4px;
  margin-bottom: 10px;
  //margin-left: 50px;
  position: relative;
  vertical-align: middle;
  border-radius: 2%;
}
.tooltip-link {
  color: #292929;
  line-height: 0;
  margin: 0 5px 0 0;
  position: relative;
  display: inline-block;
  font-size: 16px;
  width: auto;
  height: 20px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.tooltip-link:hover {
  color: #292929;
  text-decoration: none;
}

.tooltip-link:after {
  font-size: 13px;
  position: relative;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  content: '?';
  width: 20px;
  height: 20px;
  padding-left: 0px;
  line-height: normal;
  font-weight: 700;
  border: 1px solid #c4c4c4;
  background-color: #c4c4c4;
}