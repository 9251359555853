html,
body {
	margin: 0;
	font-family: $font-family;
	font-size: $font-size;
	line-height: $line-height;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-size: 1.6rem;
	font-weight: $bold;
	color: $primary;
	margin-top: 1rem;
	text-transform: uppercase;
}

.alternate {
	font-family: $alternative;
}

button {
	text-transform: uppercase;
}

$icon: "<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.0008 8L6 8.83333L10 13L14 8.83333L13.9992 8H6.0008Z' fill='%%COLOR%%'/></svg>";

select {
	padding-right: 28px;
	appearance: none;
	@include svg_icon($icon, $gray);
	background-repeat: no-repeat;
	background-size: 20px 20px;
	background-position: calc(100% - 8px) 10px;

	&.placeholder-active {
		color: $gray;
	}
}

select::-ms-expand {
	display: none;
}

$svg-indirizzo: "<svg width='19' height='24' viewBox='0 0 19 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M11.1059 20.6001L9.73703 23.2879L9.15613 24C9.11297 24 9.07209 23.9897 9.03298 23.9751C9.02215 23.971 9.01229 23.9665 9.00199 23.9615C8.97145 23.9467 8.94351 23.928 8.91832 23.9051C8.91591 23.9029 8.91335 23.901 8.91079 23.8991C8.90751 23.8966 8.90424 23.8942 8.90133 23.8912C8.87685 23.866 8.8553 23.8377 8.83885 23.8054L7.1321 20.4544C6.36348 18.9451 5.22368 17.6297 3.74451 16.5445C1.39972 14.8242 0 12.0629 0 9.15807C0 6.61699 1.06922 4.16822 2.9333 2.43992C4.82242 0.688318 7.28249 -0.167402 9.86633 0.027143C14.3732 0.368423 17.9955 4.02809 18.2921 8.54001C18.4967 11.6566 17.1283 14.6311 14.6316 16.4967C13.0938 17.6455 11.9076 19.0261 11.1059 20.6001ZM9.15614 3.48463C12.1861 3.48463 14.6512 5.94973 14.6512 8.9797C14.6512 12.0097 12.1861 14.4748 9.15614 14.4748C6.12618 14.4748 3.66107 12.0097 3.66107 8.9797C3.66107 5.94973 6.12618 3.48463 9.15614 3.48463Z' fill='%%COLOR%%'/></svg>";
$svg-tipologia: "<svg width='29' height='24' viewBox='0 0 29 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M27.7741 7.04694L14.7563 0.15029C14.5716 0.0516173 14.3654 0 14.1559 0C13.9465 0 13.7402 0.0516173 13.5555 0.15029L0.711359 7.04621C0.557874 7.12201 0.421199 7.22791 0.309466 7.3576C0.197733 7.4873 0.113221 7.63813 0.0609579 7.80115C0.00869431 7.96416 -0.0102533 8.13601 0.00524031 8.3065C0.0207339 8.47698 0.0703529 8.64261 0.151147 8.79353C0.231941 8.94445 0.342259 9.07758 0.475542 9.185C0.608824 9.29243 0.762348 9.37195 0.926985 9.41885C1.09162 9.46575 1.26401 9.47906 1.43389 9.45799C1.60378 9.43692 1.76769 9.3819 1.91588 9.29621L2.83879 8.80083V22.7232C2.83879 23.0619 2.9733 23.3866 3.21274 23.626C3.45218 23.8655 3.77693 24 4.11554 24H10.7765C10.9445 24.0005 11.1109 23.9678 11.2662 23.9039C11.4216 23.8399 11.5627 23.7459 11.6817 23.6273C11.8006 23.5087 11.895 23.3678 11.9594 23.2127C12.0238 23.0575 12.0569 22.8912 12.0569 22.7232V16.8137H16.4241V22.7232C16.4241 23.0619 16.5587 23.3866 16.7981 23.626C17.0375 23.8655 17.3623 24 17.7009 24H24.3619C24.7005 24 25.0253 23.8655 25.2647 23.626C25.5041 23.3866 25.6386 23.0619 25.6386 22.7232V8.80374L26.5798 9.30204C26.8783 9.45414 27.2247 9.4828 27.5441 9.38185C27.8636 9.28089 28.1306 9.05842 28.2875 8.7624C28.4444 8.46638 28.4786 8.12055 28.3829 7.7995C28.2871 7.47844 28.069 7.20789 27.7756 7.04621L27.7741 7.04694ZM23.0837 21.4465H18.9762V15.537C18.9762 15.1983 18.8417 14.8736 18.6022 14.6342C18.3628 14.3947 18.0381 14.2602 17.6994 14.2602H10.7787C10.4401 14.2602 10.1154 14.3947 9.87592 14.6342C9.63648 14.8736 9.50197 15.1983 9.50197 15.537V21.4465H5.39448V7.43069L14.1639 2.72495L23.0844 7.45112L23.0837 21.4465Z' fill='%%COLOR%%'/></svg>";

.hero-indirizzo .hero-prepend {
	@include svg_icon($svg-indirizzo, $gray);
}

.hero-tipologia .hero-prepend {
	@include svg_icon($svg-tipologia, $gray);
}

.logo-container {
	position: absolute;
	top: 10;
	left: 75px;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;

	@media screen and (max-width: 992px) {
		left: 35px;
	}

	.contact-icons {
		width: 25%;
		display: flex;
		justify-content: space-around;
		cursor: pointer;
		font-size: 20px;

		a {
			color: black;
		}
	}
}