@import "../../scss/variables";
@import "../../scss/mixins";

$dimension: 60px;
$dimension-xs: 50px;
$border: 1px solid $gray-e5;

.hero {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba($gray-e5, 0.75), rgba($gray-e5, 0.3)),
    url("../../assets/hero.jpg");
  background-size: cover;
  background-position-y: bottom;
  margin-bottom: 4rem;

  @media screen and (max-width: 992px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  h1 {
    font-size: 2.25rem;

    @media screen and (max-width: 992px) {
      font-size: 1.75rem;
    }
  }

  h2 {
    color: $primary;
    font-size: 1.8rem;
    font-weight: $bold;

    @media screen and (max-width: 992px) {
      font-size: 1.25rem;
    }
  }

  &-title {
    text-shadow: 0px 0px 15px rgba($gray-e5, 0.7);
    margin-bottom: 2rem;
    text-align: center;
  }

  &-form {
    display: flex;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 0 10px 3px rgba(#000, 0.2);
    min-width: 960px;

    @media screen and (max-width: 992px) {
      min-width: auto;
      flex-direction: column;
      box-shadow: none;
      border: none;
    }

    .hero-indirizzo {
      flex: 66% 1 1;
      position: relative;

      &.is-open,
      &.is-open .hero-prepend {
        border-bottom-left-radius: 0;

        @media screen and (max-width: 992px) {
          border-bottom-right-radius: 0;
        }
      }

      &,
      & .hero-prepend {
        border-radius: 8px 0 0 8px;
      }

      @media screen and (max-width: 992px) {
        margin-bottom: 3 * $spacer;
      }
    }

    .hero-tipologia {
      flex: 33% 1 1;

      select {
        background-position: calc(100% - 8px) 50%;
      }

      @media screen and (max-width: 992px) {
        margin-bottom: 3 * $spacer;

        & .hero-prepend {
          border-radius: 8px 0 0 8px;
        }
      }
    }

    .hero-tipologia,
    .hero-indirizzo {
      > * {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .hero-prepend {
      width: $dimension;
      height: $dimension;
      background-color: white;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: -1px;

      @media screen and (max-width: 992px) {
        width: $dimension-xs;
        height: $dimension-xs;
      }
    }

    .form-control,
    .btn {
      height: $dimension;
      border-radius: 0;
      border: 0;
      box-shadow: none;

      @media screen and (max-width: 992px) {
        border-radius: 0 8px 8px 0;
        height: $dimension-xs;
      }
    }

    .form-control {
      width: calc(100% - #{$dimension} + 1px);

      @media screen and (max-width: 992px) {
        width: calc(100% - #{$dimension-xs} + 1px);
      }
    }

    .btn {
      border-radius: 0 8px 8px 0;

      @media screen and (max-width: 992px) {
        border-radius: 8px;
      }
    }
  }
}

.autocomplete {
  position: absolute;
  top: $dimension;
  right: 0;
  z-index: 10;
  left: -1px;
  background-color: white;
  border: $border;
  box-shadow: 0 2px 5px 1px rgba(#000, 0.1);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  max-height: 220px;
  overflow-y: scroll;

  @media screen and (max-width: 992px) {
    top: $dimension-xs;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      min-height: 40px;
      padding: 10px 15px;
      color: $gray;
      cursor: pointer;

      &:hover {
        color: $text-color;
        background-color: $gray-f5;
      }

      @media screen and (max-width: 992px) {
        height: auto;
      }

      &.no-hover {
        cursor: default;

        &:hover {
          color: $gray;
          background-color: white;
        }
      }
    }
  }
}
