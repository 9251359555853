@import '../../scss/variables';

$c-01: rgba($gray, .6);
$c-02: $gray-e5;
$base-width: 7px;
$height: $base-width*2;
$duration: 2.5s;

.content {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.loading {
		width: $base-width * 10;
		height: $height;
		position: relative;

		span {
			background-color: $c-01;
			border-radius: $height/2;
			display: block;
			height: 10px;
			width: $base-width*2;
			bottom: 0;
			position: absolute;
			transform: translateX($base-width*8);
			animation: loading $duration ease both infinite;

			&:before {
				position: absolute;
				content: "";
				width: 100%;
				height: 100%;
				background-color: $c-02;
				border-radius: inherit;
				animation: loading2 $duration ease both infinite;
			}

			@keyframes loading {
				0% {
					width: 16px;
					transform: translateX(0px);
				}

				40% {
					width: 100%;
					transform: translateX(0px);
				}

				80% {
					width: 16px;
					transform: translateX($base-width*8);
				}

				90% {
					width: 100%;
					transform: translateX(0px);
				}

				100% {
					width: 16px;
					transform: translateX(0px);
				}
			}

			@keyframes loading2 {
				0% {
					transform: translateX(0px);
					width: 16px;
				}

				40% {
					transform: translateX(0%);
					width: 80%;
				}

				80% {
					width: 100%;
					transform: translateX(0px);
				}

				90% {
					width: 80%;
					transform: translateX($base-width*2);
				}

				100% {
					transform: translateX(0px);
					width: 16px;
				}
			}
		}
	}
}