@import '../../scss/variables';

.input-group .btn {
	color: $input-group-addon-color;
	background-color: $input-group-addon-bg;
	border-color: $input-border-color;

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	&:hover {
		color: $input-group-addon-color;
		background-color: darken($input-group-addon-bg, 5%);
		border-color: $input-border-color;
	}

	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled).active:focus {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, .03), 0 0 0 0 rgba($gray, .2);
	}
}

.form-control-number {
	text-align: right;
}