@import './../../scss/variables';

.stepper-container {
	display: flex;
	margin: 0;
	padding: 0;
	list-style-type: none;
	justify-content: space-between;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 2px;
	}

	&.step-active-1:before {
		background-image: linear-gradient(to right, $primary, $gray, $gray);
	}

	&.step-active-2:before {
		background-image: linear-gradient(to right, $gray, $primary, $gray);
	}

	&.step-active-3:before {
		background-image: linear-gradient(to right, $gray, $gray, $primary);
	}

	.step {
		font-size: .9rem;
		font-weight: $bold;
		text-transform: uppercase;
		color: $gray;
		padding: 4px 0px;
		background-color: transparent;
		transition: background-color .3s ease-in-out;

		&.active {
			padding: 4px 7px;
			background-color: $primary;
			color: white;
		}
	}
}