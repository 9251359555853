@import './../../scss/variables';

.multiple-select {
	display: flex;

	.btn-group .btn {
		background-color: rgba(white, .85);
		border-color: $gray-e5;
		color: $gray;
		font-weight: normal;

		&.is-active {
			background-color: $primary;
			border-color: $primary;
			color: white;
		}
	}
}