@import '../../scss/variables';

.menu {
  margin: 0;
  list-style-type: none;
}

.menu > li {
  display: table-cell;
  vertical-align: middle;
}

.menu > li > a {
  display: inline-block;
  padding: 0.7rem 0.5rem;
  line-height: 1;
}

.link-tel {
  padding-left: 0px;
}

.menu > li > a {
  color:$black;
  text-decoration: none;
  cursor: pointer;
}

.menu > li > a:hover {
  color: $gray;
}
.top-bar {
  padding: 0 2.5rem;
}

.top-bar::before, .top-bar::after {
  display: table;
  content: ' ';
}
.top-bar::after {
  clear: both;
}

.top-bar,.top-bar ul {
  background-color: $white;
  font-size: 16px;

  @media (max-width: 600px) {
    &{
      font-size: 13px;
    }
  }

}
.top-bar-left {
  float: left;
}

.top-bar-right {
  float: right;
}

@media (max-width: 600px) {
  .top-bar {
    padding: 0 0.5rem;
  }
}