@import '../../scss/variables';

.form-container {
	width: 66%;
	background-color: #f6f6f6;
	padding: 60px;
	height: 100vh;

	@media screen and (max-width: 992px) {
		width: 100%;
		padding: 20px;
		padding-top: 80px;
	}

	.form-content {
		overflow-y: scroll;
		overflow-x: hidden;
		height: calc(100% - 20px);
	}

	.form-grid {
		display: grid;
		grid: auto-flow auto / 100%;
		gap: map-get($map: $spacers, $key: 5);
	}
}

.main {
	width: 100vw;
	min-height: 100vh;
	background: url('../../assets/main.jpg');
	background-size: cover;
}

.back {
	background-color: rgba(white, .85);
	border-color: $gray-e5;
	color: $gray;
}

.result-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;

	&.loader {
		align-items: center;

		.content {
			height: auto;
		}
	}
}

.small {
	font-size: .8rem;
}

a {
	color: $primary;
}